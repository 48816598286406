:root {
  --fc-border-color: var(--color-border);
  --fc-event-bg-color: var(--color-schedulerActiveEventCardColor);
  --fc-event-border-color: var(--color-schedulerActiveEventCardColor);
  --fc-event-text-color: var(--color-textPrimary);
}

.fc .fc-scrollgrid {
  background-color: var(--color-background-white);
  border-collapse: separate;
  border-radius: 8px;
  border: 1px solid var(--color-border);
  color: var(--color-textSecondary);
  overflow: hidden;
}

.fc col {
  /* stylelint-disable-next-line declaration-no-important --
     !important required to set the full-calendar scheduler
     first column (time) width instead of letting it be computed */
  min-width: 80px !important;
}

.fc-day-today {
  /* stylelint-disable-next-line declaration-no-important --
     !important required to overwrite the default yellow color
     for the active day column at the full-calendar scheduler */
  background: inherit !important;
}

.fc .fc-scrollgrid-section-liquid > td,
.fc .fc-scrollgrid-section-footer > *,
.fc .fc-scrollgrid-section-header > * {
  border: none;
}

.fc .fc-timegrid-slot {
  height: 62px;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  height: 100%;
  width: 100%;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  font-size: 13px;
  font-weight: 400;
  padding: 4px 8px;
  text-transform: uppercase;
}

.fc-theme-standard th {
  height: 32px;

  & .fc-scrollgrid-sync-inner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: left;
    padding: 0 12px;

    & > .fc-col-header-cell-cushion {
      font-size: 14px;
      font-weight: 400;
      padding: 0;
    }
  }
}
.fc-v-event {
  border-radius: 4px;
  margin: 4px;
}

.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 6px 0 0;
}

.fc-v-event .fc-event-main-frame {
  padding: 8px 20px 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fc-timegrid-event .fc-event-main {
  cursor: pointer;
  display: flex;
}

.fc-v-event .fc-event-main::before {
  border-radius: 100px;
  content: '';
  display: flex;
  margin: 8px;
  width: 4px;
}

.fc-v-event .fc-event-title-container {
  flex-grow: unset;
}

.fc-v-event .fc-event-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.fc-timegrid-event .fc-event-time {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-transform: lowercase;
}
